.fixed-theme {
  /* ======================================== Nav bar ======================================== */

  width: 100%;

  @media (max-width: 420px) {
    max-width: 420px;
  }

  .p-sidebar-content {
    padding-top: 15px;

    border-top: 1px solid @color-gray;
    opacity: 1;

    .userScreenName {
      color: @color-primary;
      font-weight: bold;
    }

    a.no-link {
      color: @color-primary;
      text-decoration: none;
    }

    hr {
      border-bottom: 1px solid @color-gray;
      opacity: 1;
      margin: 5px 0;
    }
  }

  &.navbar {
    position: fixed;
    top: 0;
    z-index: 5;
    background: white;

    .csob-logo {
      img {
        width: 48px !important;
        height: 48px !important;
      }
    }

    ul.menu {
      h5 {
        padding-top: 10px;

        .link {
          cursor: pointer;
        }

        i {
          padding-right: 5px;
        }
      }

      li {
        a {
          padding: 25px 15px 5px 15px;
        }
      }
    }
  }
}
