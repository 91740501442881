@import 'colors.less';

#admin-container {
  padding: 0 20px;

  .p-button:not(.p-button-text):not(.p-button-icon):not(.p-link):not(.p-button-outlined):not(.textButton):not(.p-button-success):not(.p-button-danger) {
    background-color: @color-blue;
    border-color: @color-blue;

    &:hover {
      background-color: @color-primary-hover !important;
      border-color: @color-primary-hover !important;
    }
  }

  &.p-button-text:not(.p-button-icon):not(.p-link):not(.p-button-outlined):not(.textButton):not(.p-button-success):not(.p-button-danger) {
    color: @color-blue;

    span.p-button-icon {
      color: @color-text;
    }
  }

  .detail-panel {
    padding: 20px 0 10px 0;
    margin-left: 20px;
    height: 100%;
    min-height: calc(100vh - 75px);
    border-left: 1px solid @color-gray-dark;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .pi-times {
        cursor: pointer;
      }

      .header-text {
        font-size: 15px;
        text-transform: uppercase;
        padding: 0 10px;
        font-weight: 600;
      }
    }

    .btn-block {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .p-button {
        width: 100%;
      }
    }
  }

  .block {
    padding: 10px 20px;

    .block-title {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }

  hr {
    width: 100%;
    border-top: 1px solid @color-gray;
    opacity: 1;
    margin: 15px 0;
  }

  .form-flex-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .form-flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px
  }

  .inputError {
    font-size: 14px;
    color: @color-danger;
  }

  .disabled {
    color: @color-disabled!important;
  }

  .attachments-block {
    .custom-file-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .custom-file-icon {
        padding: 0 5px;
        cursor: pointer;
      }
    }
  }
}
