/* ================== Global variable ================== */

@import 'colors.less';
@import 'variables.less';

@font-family-text: LinotypeUnivers-430Regular, Arial, Helvetica, sans-serif;
@font-family-title: LinotypeUnivers-620CnBold, Arial, Helvetica, sans-serif;

:root {
  font-family: @font-family-text;
  font-variation-settings: normal;
  --font-family: @font-family-text;
  --font-variation-settings: normal;
}

/* ================== Global Functions ================== */

#boxShadow {
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, .2), 0 1px 15px -2px rgba(0, 0, 0, .14), 0 2px 4px 4px rgba(0, 0, 0, .12);
}

/* ================== Global style ================== */

body {
  font-family: @font-family-text;
  color: @color-text;

  .p-button {
    height: 35px !important;

    &:focus {
      box-shadow: none;
    }

    &:not(.p-button-text):not(.p-button-icon):not(.p-link):not(.p-button-outlined):not(.textButton):not(.p-button-success):not(.p-button-danger):not(.p-confirm-dialog-accept):not(.p-confirm-dialog-reject) {
      background-color: @color-blue;
      border-color: @color-blue;

      &:hover {
        background-color: @color-primary-hover !important;
        border-color: @color-primary-hover !important;
      }
    }

    &.p-button-outlined {
      color: @color-blue;
      border-color: @color-blue;
    }

    &.p-button-text:not(.p-button-icon):not(.p-link):not(.p-button-outlined):not(.textButton):not(.p-button-success):not(.p-button-danger) {
      color: @color-blue;

      span.p-button-icon {
        color: @color-text;
      }
    }
  }

  .tox {
    .tox-menu {
      z-index: 1101;
    }

    .tox-promotion {
      display: none!important;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: @font-family-title;
  font-weight: 700;
  color: @color-primary;
}

a, a:active, a:focus, a:hover, a:visited {
  color: @color-primary;
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

hr {
  margin: 20px 0;
  border-top: 1px solid #eee;
}

.left {
  float: left !important;
}

.left-flex {
  margin-left: auto !important;
  float: left !important;
}

.right {
  float: right !important;
}

.right-flex {
  margin-left: auto !important;
  padding-right: 20px !important;
  float: right !important;
}

.hidden {
  display: none!important;
}

@media (min-width: 420px) {
  .navbar {
    width: 100%;
  }
}

.link, a.link {
  color: #0099cc;
  cursor: pointer;

  &:hover {
    color: @color-primary-hover;
    text-decoration: underline;
  }

  &.disabled {
    cursor: default;
    color: @color-disabled;

    &:hover {
      color: @color-disabled;
      text-decoration: unset;
    }
  }

  i {
    margin-right: 8px;
  }
}

/* ========= Home(Start) screen content ========= */
#portal-content-start {
  width: @container-max-width * 1px;
  margin: 0 auto;

  @media (min-width: 420px) and (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 420px) {
    max-width: 100vw;
  }

  @media (min-width: 420px) {
    // left, center, right
    & > div {
      display: inline-block;
      float: left;
    }
  }
}

/* ========= Header + Menu ========= */
#portal-content-stripe {
  margin-top: 75px;
  z-index: 1;
}

#portal-top-menu-stripe {

  .container {
    justify-content: left;
    max-width: 100%;
  }

  .navbar {
    //box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    outline: 4px solid #eee;
    border-bottom: 1px solid #ddd;
    padding: 5px 25px 0 25px;
    height: 70px;

    .csob-logo {
      margin-right: 50px;

      img {
        width: 40px;
      }
    }

    @media (max-width: 420px) {
      .container {
        max-width: 100%;
      }

      .csob-logo {
        margin-right: 10px;
      }

      .right-flex {
        padding-right: 10px !important;
      }
    }

    /* ========= Top menu ========= */
    #menu-mobile {
      padding: 10px 20px;

      @media (min-width: 1150px) {
        display: none;
      }
    }

    .client-menu {
      @media (max-width: 1150px) {
        display: none;
      }
    }

    .menu {

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 180px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
        border-radius: 10px;
        left: 50% !important;
        right: unset !important;
        transform: translatex(-75%);
      }

      .userScreenName {
        text-align: center;
        color: @color-primary;
        font-weight: bold;

        p {
          padding-top: 15px;
        }
      }

      .dropdown-content a {
        float: none;
        color: @color-primary;
        text-decoration: none;
        display: block;
        text-align: center;
      }

      .dropdown-content a:hover {
        background-color: #ddd;
        border-radius: 10px;
      }

      .dropdown:hover .dropdown-content {
        display: block;
      }
    }

    ul.menu {
      list-style-type: none;
      padding: 0;
      margin: 0;

      &.left {
        a {
          font-weight: bold;
        }
      }

      li {
        display: inline-block;

        a {
          display: inline-block;
          height: 65px;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          color: @color-primary;
          text-decoration: none;

          &.active {
            font-weight: 900;
            border-bottom: 3px solid @color-blue;
          }

          &:hover {
            color: @color-primary-hover;
          }
        }
      }
    }
  }
}

@import 'portal.fixed.theme.style.less';
@import 'portal.primeng.style.less';
@import 'portal.admin.style.less';
