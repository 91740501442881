@import 'colors.less';

.p-breadcrumb {
  padding: 0;
  border: 0;
  margin: 20px 0 10px 0;

  .p-breadcrumb-list {
    margin-left: 0;
    padding-left: 0;

    .p-element {
      &:last-child {
        .p-menuitem-link {
          cursor: default;

          &:hover {
            text-decoration: none;
          }

          .p-menuitem-text {
            font-weight: 700;
          }
        }
      }

      .p-menuitem-link {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        .p-menuitem-text {
          font-size: 18px;
        }
      }
    }
  }
}

.p-button {
  padding: 5px 10px;
  border-radius: 5px;

  span {
    font-size: 16px;
  }
}

.p-menu {
  .p-menu-list {
    padding-left: 0;
    margin-bottom: 0;

    .p-menuitem-link {
      padding: 10px;

      .p-menuitem-text {
        font-size: 14px;
      }

      img.p-menuitem-icon, span.p-menuitem-icon {
        width: 20px;
      }
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    margin-bottom: 0;
    padding-left: 0;

    .p-tabmenuitem {
      display: unset;
      flex: 1;

      &.p-highlight {
        .p-menuitem-link {
          font-weight: 700;
        }
      }

      .p-menuitem-link {
        padding: 10px 20px;
        font-weight: 400;
        justify-content: center;
      }
    }
  }
}

.p-datatable-table {
  .p-datatable-thead, .p-datatable-tbody {
    font-size: 14px;

    tr {
      th, td {
        padding: 10px 5px;
      }
    }
  }

  .p-datatable-thead {
    tr {
      th {
        background: @color-white;
        border-bottom: 1px solid @color-gray-dark;
      }
    }
  }

  .p-datatable-tbody {

    tr {
      &:not(.selected):hover {
        background-color: @color-gray-light;
      }

      &.selected {
        background-color: @table-selected-color;
      }

      td {
        border-bottom: 1px solid @table-hover-color;

        .name {
          padding-left: 10px;
        }
      }
    }
  }
}

.p-tree {
  padding: 10px;

  .p-tree-container {
    padding: 0;
    margin-bottom: 0;

    .p-treenode-content {
      padding: 0;

      .p-tree-toggler {
        height: 26px;
      }

      .p-checkbox-box {
        width: 20px;
        height: 20px;
      }

      .p-treenode-label {
        font-size: 14px;
        padding-bottom: 4px;
      }
    }
  }
}

.p-dialog, .p-dialog-mask {
  z-index: 1000!important;

  .p-dialog-content {
    overflow-y: auto;
    padding: 10px 20px;
  }

  .footer-row {
    p-button {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2px;

    hr {
      width: 100%;
      border-top: 1px solid @color-gray;
      opacity: 1;
    }

    p-button {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .p-button {
        min-width: 300px;
      }
    }
  }
}

.p-message {
  margin: 0;

  .p-message-wrapper {
    padding: 5px 10px;
    font-size: 14px;
  }
}

.p-galleria .p-galleria-item-nav {
  color: @color-blue;
  border-radius: 50px;
}

.p-multiselect {
  &.filter {
    width: 300px;
  }

  .p-multiselect-label, .p-placeholder, .p-inputtext, .p-multiselect-item {
    padding: 5px 10px;
  }
}

.p-dropdown, .p-dropdown-panel {

  &:not(.numberOfRecords, .filter) {
    min-width: 300px;
  }

  &.filter {
    width: 217px;

    .p-dropdown-items-wrapper {
      max-width: 217px !important;
    }
  }

  &.fullDropdown {
    width: 100%;

    .p-dropdown-items-wrapper {
      max-width: 100% !important;
    }
  }

  .p-inputtext {
    padding: 5px 10px;
  }

  .p-overlay, .p-dropdown-items-wrapper {
    max-width: 300px!important;

    .p-dropdown-header {
      padding: 10px;

      .p-dropdown-filter {
        padding: 5px 10px;
      }
    }

    .p-dropdown-items {
      padding-left: 0;
      margin-bottom: 0;

      .p-dropdown-item {
        padding: 5px 10px;

        &:empty {
          padding: 0;
        }
      }
    }
  }
}

.p-tooltip {
  .p-tooltip-text {
    padding: 8px;
    font-size: 14px;
    background: #aaa;
  }

  .p-tooltip-arrow {
    border-right-color: #aaa;
  }
}

i[tooltipstyleclass="info"] {
  color: @color-blue;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
}

input[pInputText], textarea[pinputtextarea], .p-calendar input {
  padding: 5px 10px;
  border: 1px solid #d1d5db;
  border-radius: 6px;

  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #BFDBFE;
  }

  &.error {
    border: 2px solid @color-danger;
  }

  &:not([disabled]) {
    background-color: #ffffff;
  }
}

.p-confirm-dialog {
  .p-dialog-header, .p-dialog-content, .p-dialog-footer {
    padding: 15px;
  }

  .p-dialog-content {
    .p-confirm-dialog-icon {
      font-size: 20px;
    }
  }

  .p-dialog-footer {
    .p-button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &.p-confirm-dialog-reject {
        background-color: @color-button-danger !important;
        border-color: @color-button-danger !important;

        &:hover {
          background-color: @color-button-danger-hover !important;
          border-color: @color-button-danger-hover !important;
        }
      }

      &.p-confirm-dialog-accept {
        background-color: @color-button-success !important;
        border-color: @color-button-success !important;

        &:hover {
          background-color: @color-button-success-hover !important;
          border-color: @color-button-success-hover !important;
        }
      }
    }
  }
}

.p-galleria .p-galleria-indicators button {
  border-radius: 50% !important;
}


.p-sidebar .p-sidebar-header {
  padding: .75rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  margin-left: auto;
}

p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  right: 1.25rem;
  margin-top: 0;
}

.p-calendar {
  min-width: 100%;

  .p-datepicker {
    padding: 0;

    .p-datepicker-header {
      padding: 0;
    }

    table {
      thead {
        margin-bottom: 5px;
        th {
          padding: 0 0 10px 0;
          text-align: center;
        }
      }

      td {
        padding: 0;

        & > span {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

.p-fileupload {
  .p-fileupload-buttonbar {
    background: transparent;
    padding: 0;
    border: 0;

    .p-button.textButton {
      background: transparent;
      background-color: transparent !important;
      border: 0 !important;
      padding: 0;
      color: @color-blue;

      &:hover {
        text-decoration: underline;
        background: transparent;
        background-color: transparent !important;
        border: 0 !important;
        padding: 0;
        color: @color-primary-hover;
      }

      .p-button-label {
        font-weight: 400;
      }
    }
  }

  .p-fileupload-content {
    padding: 10px 0 0 0;
    border: 0;

    p-progressbar {
      display: none;
    }

    .custom-file-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .custom-file-icon {
        padding: 0 5px;
        cursor: pointer;
      }
    }
  }
}

.p-toast-message {
  width: 250px;
  float: right;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(30px);

  .p-toast-message-content {
    padding: 5px 10px;

    &.single {
      align-items: center;

      .p-toast-message-icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .p-toast-detail {
        display: none;
      }
    }

    .p-toast-message-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.p-checkbox {
}

.p-checkbox-label {
  cursor: pointer;
}
